import { required } from 'vuelidate/lib/validators';
import { ModelSelect, BasicSelect } from 'vue-search-select';
import ModulesList from '../../../admin/responsibility/searchModule';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'addEditOpenClosePeriod',
  props: ['periodRowData'],
  components: {
    ModelSelect,
    BasicSelect,
    ModulesList
  },
  data() {
    return {
      showHideModule: false,
      setTimeVsetCode: null,
      showCommanModal: false,
      compName: null,
      vsetCode: null,
      showValueSetModal: null,
      unsubscribe: null,
      editMode: true,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      selectedLegalEntity: {
        value: null,
        text: null
      },
      selectedLedger: {
        valeu: null,
        text: null
      },
      ledgerList: [],
      selectedCalendar: {
        value: null,
        text: null
      },
      calendarList: [],
      selectedFinantialYear: {
        value: null,
        text: null
      },
      finantialYearList: [],
      selectedModuleObj: {
        id: null,
        name: null
      },
      selectedCalName: {
        value: null,
        text: null
      },
      selectedPeriodDetails: {
        value: null,
        text: null
      },
      periodDetailList: [],
      calList: [],
      selectedFlexField: {
        id: null,
        name: null
      },
      selectedCurrency: {
        id: null,
        name: null
      },
      selectedAccounting: {
        id: null,
        name: null
      },
      calendarForm: {
        calendar_hdr_id: 0,
        calendar_name: null,
        description: null
      },
      gridData: [],
      gridFields: [
        {
          key: 'entity_name',
          label: 'Entity'
        },
        {
          key: 'entity_period_status_meaning',
          label: 'Period Status'
        },
        {
          key: 'last_updated_by',
          label: 'Updated By'
        },
        {
          key: 'last_update_date',
          label: 'Updated Date'
        }
      ],
      formData: {
        period_status_vset: null,
        period_status_meaning: null,
        period_hdr_id: null,
        period_dtl_id: null,
        calendar_line_id: null,
        calendar_dtl_id: null,
        period_name: null,
        period_start_date: null,
        period_end_date: null,
        quater_num: null,
        period_num: null,
        prefix: null,
        calendar_name: null,
        calendar_hdr_id: null,
        sob_id: null,
        ledger_name: null,
        le_id: null,
        le_name: null,
        module_id: null,
        module_name: null,
        fy_display: null,
        entity_status_details: []
      },
      detailIndex: null
    };
  },
  validations: {
    calendarForm: {
      calendar_name: {
        required
      },
      description: { required }
    }
  },
  mounted() {
    if (this.periodRowData) {
      this.periodDtlId = this.periodRowData.period_dtl_id;
      this.calendarDetailId = this.periodRowData.calendar_hdr_id;
      if (this.periodDtlId && this.calendarDetailId) {
        this.getPeriodDetails(this.periodDtlId);
      } else {
        this.fillPeriodDetailsByRow(this.periodRowData);
      }
    }
    this.getCalenderLovList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          if (this.editMode) {
            this.savePeriodHdr();
          } else {
            this.updatePeriodDetails();
          }
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.calendarDetailId });
        }
      }
    });
  },
  methods: {
    savePeriodHdr() {
      this.loader = true;
      const payload = {
        calendar_hdr_id: this.selectedCalendar.value,
        calendar_line_id: this.selectedPeriodDetails.value,
        le_id: this.formData.le_id,
        module_id: this.formData.module_id,
        period_dtl_id: 0,
        period_status_vset: this.formData.period_status_vset,
        sob_id: this.selectedLedger.value
      };
      this.$store
        .dispatch('openClose/savePeriodHdr', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.formData = response.data.data;
            this.getPeriodDetails(response.data.data.period_dtl_id);
            this.$emit('updateList');
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updatePeriodDetails() {
      const periodDetails = this.formData.entity_status_details.map(data => {
        return {
          entity_period_status: data.entity_period_status,
          period_line_id: data.period_line_id
        };
      });
      if (periodDetails) {
        this.loader = true;
        const payload = {
          period_details: periodDetails
        };
        this.$store
          .dispatch('openClose/updatePeriodDetails', payload)
          .then(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.respMessage = 'Record successfully updated';
            this.getPeriodDetails(this.periodDtlId);
            this.$emit('updateList');
          })
          .catch(() => {
            this.loader = false;
            this.respMessage = 'Something went wrong';
          });
      }
    },
    checkGstCount(item) {
      if (item.gst_count > 0) {
        const prompt = confirm(
          `${this.formData.period_name} Gstr-3b has been already locked for ${item.entity_name}. Do you wish to lock the GSTR-3B again?`
        );
        if (prompt) {
          this.lockGstr3B(item);
        }
      } else {
        this.lockGstr3B(item);
      }
    },
    lockGstr3B(item) {
      const payload = {
        entity_name: item.entity_name.toString(),
        period_name: this.formData.period_name.toString()
      };
      this.loader = true;
      this.$store
        .dispatch('openClose/lockGstr3B', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.$bvToast.toast(response.data.message, {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
            this.getPeriodDetails(this.periodDtlId);
          }else{
            this.$bvToast.toast(response.response.data.message, {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPeriodDetails(periodDtlId) {
      this.loader = true;
      this.$store
        .dispatch('openClose/getGlOpenClosePeriodDtl', periodDtlId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.formData = response.data.data;
            this.selectedLedger.value = this.formData.sob_id;
            this.selectedLedger.text = this.formData.ledger_name;
            this.selectedCalendar.value = this.formData.calendar_hdr_id;
            this.selectedCalendar.text = this.formData.calendar_name;
            this.selectedFinantialYear.value = this.formData.calendar_hdr_id;
            this.selectedFinantialYear.text = this.formData.fy_display;
            this.selectedPeriodDetails.value = this.formData.calendar_line_id;
            this.selectedPeriodDetails.text = this.formData.period_name;
            if (
              this.formData.module_id === 18 &&
              this.formData.module_name === 'GST'
            ) {
              this.gridFields.push({
                key: 'lock_gstr3b',
                label: 'Lock GSTR3B'
              });
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    fillPeriodDetailsByRow(item) {
      this.formData = item;
      this.selectedLedger = {
        value: this.formData.sob_id,
        text: this.formData.ledger_name
      };
      this.selectedCalendar = {
        value: this.formData.calendar_hdr_id,
        text: this.formData.calendar_name
      };
      this.selectedFinantialYear = {
        value: this.formData.calendar_hdr_id,
        text: this.formData.fy_display
      };
      this.selectedPeriodDetails = {
        value: this.formData.calendar_line_id,
        text: this.formData.period_name
      };
      this.periodDetailList = [this.selectedPeriodDetails];
    },
    getCalenderLovList() {
      const payload = {
        calendar_name: ''
      };
      this.$store.dispatch('sob/getCalenderLov', payload).then(response => {
        if (response.status === 200) {
          const calendarList = response.data.data.map(data => {
            return {
              value: data.calendar_hdr_id,
              text: data.calendar_name
            };
          });
          this.calList = calendarList;
        }
      });
    },
    showHideCommonModal(flag, compName) {
      this.showCommanModal = flag;
      this.compName = compName;
    },
    selectedModalData(item) {
      if (this.compName === 'flexField') {
        this.selectedFlexField.id = item.flexfield_id;
        this.selectedFlexField.name = item.flexfield_name;
      } else if (this.compName === 'currency') {
        this.selectedCurrency.id = item.currency_id;
        this.selectedCurrency.name = item.currency_name;
      }
      this.showCommanModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.detailIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.detailIndex !== null) {
        this.formData.entity_status_details[
          this.detailIndex
        ].entity_period_status = item.value_code;
        this.formData.entity_status_details[
          this.detailIndex
        ].entity_period_status_meaning = item.value_meaning;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity.value = item.org_id;
        this.selectedLegalEntity.text = item.org_name;
        this.formData.le_name = item.org_name;
        this.formData.le_id = item.org_id;
        this.getLedgerListData(item.org_id);
        this.prefillSearchDataByLeId(item.org_id);
      } else if (this.vsetCode === 'PERIOD_STATUS') {
        this.formData.period_status_vset = item.value_code;
        this.formData.period_status_meaning = item.value_meaning;
      }
      this.showValueSetModal = false;
    },
    setSelectedCalendar(option) {
      this.formData.calendar_name = option.text;
      this.formData.calendar_hdr_id = option.value;
      this.selectedCalendar = option;
      this.getFinantialYearList(option.value);
    },
    selectedFyYear(option) {
      // this.formData. = option.text;
      // this.formData.calendar_hdr_id = option.value;
      this.selectedFinantialYear = option;
      this.getCalendarPeriodDetail(option.value);
    },
    prefillSearchDataByLeId(leId) {
      this.$store
        .dispatch('openClose/prefillSearchDataByLeId', leId)
        .then(response => {
          if (response.status === 200) {
            const resp = response.data.data;
            this.selectedLedger.value = resp.sob_id;
            this.selectedLedger.text = resp.sob_name;
            this.formData.module_name = resp.module_name;
            this.formData.module_id = resp.module_id;
          }
        });
    },
    getFinantialYearList(calId) {
      const payload = {
        calendar_hdr_id: calId,
        searchType: 'financial-years'
      };
      this.$store
        .dispatch('openClose/getPeriodsLov', payload)
        .then(response => {
          if (response.status === 200) {
            const modifyArr = response.data.data.map(data => {
              return {
                value: data.calendar_dtl_id,
                text: data.financial_year_display_name
              };
            });
            this.finantialYearList = modifyArr;
          }
        });
    },
    getLedgerListData(leId) {
      const payload = {
        le_id: leId,
        searchType: 'ledger-lists'
      };
      this.$store
        .dispatch('openClose/getPeriodsLov', payload)
        .then(response => {
          if (response.status === 200) {
            const modifyArr = response.data.data.map(data => {
              return {
                value: data.sob_id,
                text: data.sob_name
              };
            });
            this.ledgerList = modifyArr;
          }
        });
    },
    setSelectedLedger(option) {
      this.selectedLedger = option;
      this.getCalenderNameList(option.value);
    },
    getCalenderNameList(sobId) {
      const payload = {
        sob_id: sobId,
        searchType: 'calendar-names'
      };
      this.$store
        .dispatch('openClose/getPeriodsLov', payload)
        .then(response => {
          if (response.status === 200) {
            const modifyArr = response.data.data.map(data => {
              return {
                value: data.calendar_hdr_id,
                text: data.calendar_name
              };
            });
            this.calendarList = modifyArr;
          }
        });
    },
    selectedModule(module) {
      this.formData.module_name = module.module_name;
      this.formData.module_id = module.module_id;
      this.showHideModule = false;
    },
    hideModuleModal(flag) {
      this.showHideModule = flag;
    },
    getCalendarPeriodDetail(calHdrId) {
      if (calHdrId) {
        this.loader = true;
        this.$store
          .dispatch('calendar/getCalendarPeriodDetails', calHdrId)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.periodDetailList = response.data.data.calendar_line_dtls.map(
                data => {
                  return {
                    value: data.calendar_line_id,
                    text: data.period_name
                  };
                }
              );
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.setTimeVsetCode);
  }
};
