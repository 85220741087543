import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import addEditOpenClosePeriod from './addEditOpenClosePeriod';
import { ModelSelect, BasicSelect } from 'vue-search-select';
import ModulesList from '../../admin/responsibility/searchModule';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'openClosePeriod',
  components: {
    addEditOpenClosePeriod,
    ModelSelect,
    BasicSelect,
    ModulesList
  },
  watch: {
    currentPage: function() {
      this.getPeriodList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getPeriodList();
    }
  },
  data() {
    return {
      periodDtlId: null,
      showHideModule: null,
      vsetCode: null,
      setTimeVsetCode: null,
      payload: {},
      showValueSetModal: false,
      calendarDetailId: null,
      unsubscribe: null,
      showAddCalender: false,
      loader: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      valueSetName: null,
      currentPage: 1,
      totalRows: null,
      selectedLegalEntity: {
        value: null,
        text: null
      },
      selectedLedger: {
        valeu: null,
        text: null
      },
      ledgerList: [],
      selectedCalendar: {
        value: null,
        text: null
      },
      calendarList: [],
      selectedFinantialYear: {
        value: null,
        text: null
      },
      finantialYearList: [],
      selectedModuleObj: {
        id: null,
        name: null
      },
      periodRowData: null,
      openClosePeriodList: [],
      openClosePeriodFields: [
        {
          key: 'period_name'
        },
        {
          key: 'period_start_date',
          label: 'Start Date'
        },
        {
          key: 'period_end_date',
          label: 'End Date'
        },
        {
          key: 'period_num',
          label: 'Period Number'
        },
        {
          key: 'quater_num',
          label: 'Quater Number'
        },
        {
          key: 'period_status_meaning',
          label: 'Status'
        }
      ]
    };
  },
  validations: {
    selectedLegalEntity: {
      value: {
        required
      }
    },
    selectedLedger: {
      text: {
        required
      }
    },
    selectedModuleObj: {
      id: {
        required
      },
      name: {
        required
      }
    },
    selectedCalendar: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.periodDtlId = null;
          this.calendarDetailId = null;
          this.showAddCalender = true;
          this.periodRowData = null;
        }
        if (actionName === 'download' && !this.showAddCalender) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'openClose/getPeriodList',
            'open-closeperiod',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    clear() {
      this.selectedLegalEntity = {
        value: null,
        text: null
      };
      this.selectedLedger = {
        valeu: null,
        text: null
      };
      this.selectedModuleObj = {
        id: null,
        name: null
      };
      this.selectedCalendar = {
        value: null,
        text: null
      };
      this.selectedFinantialYear = {
        value: null,
        text: null
      };
      this.openClosePeriodList = [];
    },
    rowSelected(item) {
      this.periodRowData = item;
      this.showAddCalender = true;
    },
    hideModal() {
      this.showAddCalender = false;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity.value = item.org_id;
        this.selectedLegalEntity.text = item.org_name;
        this.getLedgerListData(item.org_id);
        this.prefillSearchDataByLeId(item.org_id);
      } else {
        this.selectedAccounting.id = item.accounting_method_id;
        this.selectedAccounting.name = item.accounting_method_name;
      }
      this.showValueSetModal = false;
    },
    getLedgerListData(leId) {
      const payload = {
        le_id: leId,
        searchType: 'ledger-lists'
      };
      this.$store
        .dispatch('openClose/getPeriodsLov', payload)
        .then(response => {
          if (response.status === 200) {
            const modifyArr = response.data.data.map(data => {
              return {
                value: data.sob_id,
                text: data.sob_name
              };
            });
            this.ledgerList = modifyArr;
          }
        });
    },
    prefillSearchDataByLeId(leId) {
      this.$store
        .dispatch('openClose/prefillSearchDataByLeId', leId)
        .then(response => {
          if (response.status === 200) {
            const resp = response.data.data;
            this.selectedLedger.value = resp.sob_id;
            this.selectedLedger.text = resp.sob_name;
            this.selectedCalendar.value = resp.calendar_hdr_id;
            this.selectedCalendar.text = resp.calendar_name;
            this.selectedModuleObj.name = resp.module_name;
            this.selectedModuleObj.id = resp.module_id;
            this.getFinantialYearList(this.selectedCalendar.value);
          }
        });
    },
    setSelectedLedger(option) {
      this.selectedLedger = option;
      this.getCalenderNameList(option.value);
    },
    getCalenderNameList(sobId) {
      const payload = {
        sob_id: sobId,
        searchType: 'calendar-names'
      };
      this.$store
        .dispatch('openClose/getPeriodsLov', payload)
        .then(response => {
          if (response.status === 200) {
            const modifyArr = response.data.data.map(data => {
              return {
                value: data.calendar_hdr_id,
                text: data.calendar_name
              };
            });
            this.calendarList = modifyArr;
          }
        });
    },
    setSelectedCalendar(option) {
      this.selectedCalendar = option;
      this.getFinantialYearList(option.value);
    },
    getFinantialYearList(calId) {
      const payload = {
        calendar_hdr_id: calId,
        searchType: 'financial-years'
      };
      this.$store
        .dispatch('openClose/getPeriodsLov', payload)
        .then(response => {
          if (response.status === 200) {
            const modifyArr = response.data.data.map(data => {
              return {
                value: data.calendar_dtl_id,
                text: data.financial_year_display_name
              };
            });
            this.finantialYearList = modifyArr;
          }
        });
    },
    getPeriodList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.selectedLegalEntity.value,
          sob_id: this.selectedLedger.value,
          module_id: this.selectedModuleObj.id,
          calendar_hdr_id: this.selectedCalendar.value,
          calendar_dtl_id: this.selectedFinantialYear.value
        };
        this.loader = true;
        this.$store
          .dispatch('openClose/getPeriodList', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.openClosePeriodList = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    selectedModule(module) {
      this.selectedModuleObj.name = module.module_name;
      this.selectedModuleObj.id = module.module_id;
      this.showHideModule = false;
    },
    hideModuleModal(flag) {
      this.showHideModule = flag;
    },
    clearVsetValues(vsetCode){
      if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.selectedLegalEntity.value = null;
        this.selectedLegalEntity.text = null;
       
      } else {
        this.selectedAccounting.id = null;
        this.selectedAccounting.name = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.setTimeVsetCode);
  }
};
